 <template>
     <div class="alert alert-success alert-flash" role="alert" v-show="show">
         <strong>Success!</strong> {{ body }}
     </div>
 </template>

 <style>
      .alert-flash {
          position: fixed;
          right: 25px;
          bottom: 25px;
          color: #3c763d;
          background-color: #dff0d8 !important;
          border-color: #d6e9c6;
      }
 </style>

 <script>
     export default {
         props: ['message'],

        data() {
            return {
                body: '',
                show: false
            }
        },

         created() {
             if (this.message) {
                this.flash(this.message);
             }
         },

         methods: {
             flash(message) {
                 this.body = message;
                 this.show= true;

                 this.hide();
             },

             hide() {
                 setTimeout(() => {
                     this.show = false;
                 }, 5000);
             }
         }
     };
 </script>