
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('flash', require('./components/Flash.vue').default);

const app = new Vue({
    el: '#page-container',

    created() {
        (function($){
            window.onbeforeunload = function(e){
                window.name += ' [' + $(window).scrollTop().toString() + '[' + $(window).scrollLeft().toString();
            };
            $.maintainscroll = function() {
                if(window.name.indexOf('[') > 0)
                {
                    var parts = window.name.split('[');
                    window.name = $.trim(parts[0]);
                    window.scrollTo(parseInt(parts[parts.length - 1]), parseInt(parts[parts.length - 2]));
                }
            };
            $.maintainscroll();
        })(jQuery);
    }

});
